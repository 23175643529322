import * as React from 'react'

import { NewsFieldsFragment, useNewsDetailLazyQuery } from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'
import useNewsAssets from '@/service/news/news-assets-hook'
import useLanguage from '@/utils/language/language-hook'
import PrimaryGradientLink from '@/components/buttons/primary-gradient-button/link'
import { MY_PORTFOLIOS_PATH } from '@/route/my/portfolios'

import NewsListItem, { NewsListItemSkeleton } from '../news-list-item'
import NewsHeadLine, { NewsHeadLineSkeleton } from '../news-head-line'

import StyledNewsList, { StyledFlatOnlyNewsList } from './steyld'

type Props = {
  news: NewsFieldsFragment[]
  loading: boolean
  moreLoading?: boolean
  firstNewsBigStyle?: boolean
  hasMore?: boolean
  fetchMore?: () => void
  isRequireSignin?: boolean
  isRequirePortfolios?: boolean
  hasDesktopStyle?: boolean
  columnCount?: number
}

const NewsList = ({
  firstNewsBigStyle = false,
  news,
  loading,
  fetchMore,
  hasMore = false,
  isRequireSignin = false,
  isRequirePortfolios = false,
  hasDesktopStyle = false,
  moreLoading = false,
  columnCount = 4,
}: Props) => {
  const { t } = useLanguage('news')
  const [newsDetailQuery] = useNewsDetailLazyQuery({ client: newsServerApolloClient() })
  const handleNewsClick = React.useCallback(
    (newsId: string) => newsDetailQuery({ variables: { newsId } }),
    [newsDetailQuery],
  )
  const { stocksById, cryptoPercentagesBySymbol } = useNewsAssets({ news })
  const { headlineNews, newsList } = React.useMemo(() => {
    if (firstNewsBigStyle) {
      const copiedNews = news.slice(0)
      const headlineNewsIndex = copiedNews.findIndex((news) => !!news.thumbnailUrl)
      if (headlineNewsIndex > -1) {
        const headlineNews = copiedNews.splice(headlineNewsIndex, 1)?.[0]
        if (headlineNews) {
          return { headlineNews, newsList: copiedNews }
        }
      }
    }
    return { headlineNews: null, newsList: news }
    // return firstNewsBigStyle ? news.slice(1) : news
  }, [firstNewsBigStyle, news])
  const StyledContainer = React.useMemo(
    () => (hasDesktopStyle ? StyledNewsList : StyledFlatOnlyNewsList),
    [hasDesktopStyle],
  )

  const moreLoadingElRef = React.useRef<HTMLLIElement>(null)
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!loading && hasMore && fetchMore) {
              observer.unobserve(entry.target)
              fetchMore()
            }
          }
        })
      },
      { rootMargin: '0px 0px 500px 0px' },
    )

    moreLoadingElRef.current && observer.observe(moreLoadingElRef.current)

    return () => {
      observer.disconnect()
    }
  }, [fetchMore, hasMore, loading])

  return (
    <StyledContainer columnCount={columnCount}>
      {/* 로그인이 필요한 경우 */}
      {!loading && isRequireSignin && (
        <div className="no-news">
          <p>{t('signin')}</p>
          <PrimaryGradientLink href="/sign-in">{t('signin:title')}</PrimaryGradientLink>
        </div>
      )}
      {/* 메타 포트폴리오 on 된 포폴이 없어서 뉴스가 없는 경우 */}
      {!loading && !isRequireSignin && isRequirePortfolios && !news?.length && (
        <div className="no-news">
          <p>{t('noMyNews.1')}</p>
          <p>{t('noMyNews.2')}</p>
          <PrimaryGradientLink href={MY_PORTFOLIOS_PATH}>{t('addAsset')}</PrimaryGradientLink>
        </div>
      )}
      {/* 뉴스가 전혀 없는 경우 */}
      {!loading && !isRequireSignin && !isRequirePortfolios && !news?.length && (
        <div className="no-news">
          <p>{t('noNews')}</p>
        </div>
      )}

      {/* 뉴스 목록 아이템 스켈레톤 */}
      {loading && !news.length && (
        <ul className="news-container">
          {/* 헤드라인 뉴스 아이템 스켈레톤 */}
          {firstNewsBigStyle && (
            <li className="headline">
              <NewsHeadLineSkeleton />
            </li>
          )}
          <li>
            <NewsListItemSkeleton hasDesktopStyle={hasDesktopStyle} />
          </li>
          <li>
            <NewsListItemSkeleton hasDesktopStyle={hasDesktopStyle} />
          </li>
          <li>
            <NewsListItemSkeleton hasDesktopStyle={hasDesktopStyle} />
          </li>
          <li>
            <NewsListItemSkeleton hasDesktopStyle={hasDesktopStyle} />
          </li>
        </ul>
      )}

      <ul className="news-container">
        {/* 최상단 헤드라인 뉴스 */}
        {headlineNews && (
          <li key={headlineNews.id} className="headline">
            <NewsHeadLine
              news={headlineNews}
              stocksById={stocksById}
              cryptoPercentagesBySymbol={cryptoPercentagesBySymbol}
              onNewsClick={handleNewsClick}
            />
          </li>
        )}
        {/* 뉴스 목록 */}
        {newsList.map((news, index) => (
          <React.Fragment key={news.id + '-' + index}>
            <li>
              <NewsListItem
                news={news}
                stocksById={stocksById}
                cryptoPercentagesBySymbol={cryptoPercentagesBySymbol}
                hasDesktopStyle={hasDesktopStyle}
                onNewsClick={handleNewsClick}
              />
            </li>
          </React.Fragment>
        ))}
        {/* more loading skeleton */}
        {((!loading && moreLoading) || hasMore) && (
          <li ref={moreLoadingElRef}>
            <NewsListItemSkeleton hasDesktopStyle={hasDesktopStyle} />
          </li>
        )}
      </ul>
    </StyledContainer>
  )
}

export default NewsList
