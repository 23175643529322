import styled from 'styled-components'

const StyledNewsList = styled.div<{ columnCount: number }>`
  > .news-container {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
      grid-gap: var(--spacing-legacy-50) var(--spacing-legacy-40);

      .headline {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }
    }

    > li {
      @media (min-width: 768px) {
        display: inline-block;
      }

      @media (max-width: 767px) {
        :not(:first-child) {
          margin-top: var(--spacing-legacy-30);
        }
      }
    }
  }

  > .no-news {
    text-align: center;
    margin: var(--spacing-legacy-50) 0;

    @media (max-width: 767px) {
      padding: 0 var(--spacing-legacy-15);
    }

    p {
      font-size: var(--font-size-15);
      line-height: 1.5;
    }
  }
`

export const StyledFlatOnlyNewsList = styled.div<{ columnCount: number }>`
  > .news-container {
    > li {
      :not(:first-child) {
        margin-top: var(--spacing-legacy-30);
      }
    }
  }

  .no-news {
    text-align: center;
    margin: var(--spacing-legacy-50) 0;

    padding: 0 var(--spacing-legacy-15);

    p {
      font-size: var(--font-size-15);
      line-height: 1.5;
    }
  }
`

export default StyledNewsList
