import * as React from 'react'

import Link, { LinkProps } from '@/components/common/link'

import { StyledPrimaryGradientLink } from './styled'

type Props = LinkProps & {
  children: React.ReactNode
}

const PrimaryGradientLink = ({ children, ...props }: Props) => {
  return (
    <Link {...props}>
      <StyledPrimaryGradientLink>{children}</StyledPrimaryGradientLink>
    </Link>
  )
}

export default PrimaryGradientLink
