import styled, { css } from 'styled-components'

const primaryGradientStyle = css`
  display: inline-block;
  margin-top: var(--spacing-legacy-20);
  background: var(--linear-gradient-227-187);
  padding: var(--spacing-legacy-15) var(--spacing-legacy-24);
  border-radius: var(--border-radius-12);
  font-size: var(--font-size-15);
`

export const StyledPrimaryGradientButton = styled.button`
  ${primaryGradientStyle}
`

export const StyledPrimaryGradientLink = styled.div`
  ${primaryGradientStyle}
`
