import styled from 'styled-components'

const StyledNewsHeadLine = styled.div`
  .news {
    position: relative;
    display: block;
    border-radius: var(--border-radius-12);
    overflow: hidden;

    .image {
      width: 100%;
      padding-top: 66%;
      display: inline-block;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bg-blur {
      background: var(--linear-gradient-black-blur);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: var(--spacing-legacy-12);
      text-shadow: var(--text-shadow);

      .title {
        color: var(--color-gray-188);
        font-size: var(--font-size-20);

        @media (max-width: 767px) {
          font-size: var(--font-size-15);
        }
      }
    }

    .sub-text {
      margin-top: var(--spacing-legacy-12);

      > span {
        color: var(--color-gray-128);
        font-size: var(--font-size-16);
        white-space: pre;

        @media (max-width: 767px) {
          font-size: var(--spacing-legacy-13);
        }
      }

      .separator {
        margin: 0 var(--spacing-legacy-5);
      }
    }

    .news-assets-container {
      display: flex;
      width: 100%;
      overflow-y: auto;
      white-space: pre;

      .news-asset {
        margin: 0;
        padding: var(--spacing-legacy-4) 0;
        background: transparent !important;

        :not(:first-child) {
          margin-left: var(--spacing-legacy-10);
        }
      }
    }
  }
`

export default StyledNewsHeadLine
